<template>
  <div class="mobil_content">
    <!-- 标题 -->
    <H5_HEAER :title="title" />
    <!-- 主体 -->
    <div class="mobie-body">
      <div class="content">
        <div class="video-box">
          <div class="vide-item" v-for="(item, index) in req" :key="index">
            <div class="item-title ellipsis">{{ item.title }}</div>
            <div class="video-info-box">
              <div class="video-left">
                <video
                  :src="$store.state.video + item.fileName"
                  width="100%"
                  height="100%"
                ></video>
              </div>
              <div class="video-right">
                <div class="video-data">
                  <div class="video-data-row">地址：</div>
                  <div class="video-data-row">视频：</div>
                  <div class="video-data-row">账号：</div>
                  <div class="video-data-row">备注：</div>
                </div>
              </div>
            </div>
            <div class="item-btns">
              <div class="btn-box">
                <el-button
                  type="primary"
                  size="small"
                  :style="{ width: '100%' }"
                  plain
                  round
                  @click="openUrl(item.releaseId)"
                  >发布二维码</el-button
                >
              </div>
              <div class="btn-box">
                <el-button
                  type="primary"
                  size="small"
                  :style="{ width: '100%' }"
                  round
                  >立即发布</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPublishList } from "@/api/publish";
import H5_HEAER from "@/components/h5/header";
export default {
  name: "PublishList",
  components: {
    H5_HEAER,
  },
  data() {
    return {
      gruopId: "",
      title: "发布视频",
      req: [],
    };
  },
  created() {
    this.gruopId = this.$route.query.groupId;
    let num = Math.floor(Math.random() * (5 - 1 + 1)) + 1;
    getPublishList({ groupId: this.gruopId, num }).then((res) => {
      this.req = res.data;
      console.log(res);
    });
  },
  mounted() {},

  methods: {
    openUrl(id) {
      this.$router.push({ path: "publish_qr", query: { releaseId: id } });
    },
  },
};
</script>

<style lang="less" scoped>
.mobil_content {
  background: linear-gradient(to bottom, #a9d5fc, #fff5f7);
  min-height: 100vh;
}
.mobie-body {
  height: 100%;

  padding: 10px;
  box-sizing: border-box;
  .content {
  }
}

.vide-item {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  .item-title {
    padding: 10px 8px;
  }
  .video-info-box {
    display: flex;
    padding: 0 10px;
    .video-left {
      flex: 0 0 140px;
      height: 140px;
      background-color: #f3f3f3;
    }
    .video-right {
      padding-left: 10px;
      flex: 1;
      .video-data {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
  }
  .item-btns {
    padding: 10px;
    display: flex;
    .btn-box {
      flex: 1;
      padding: 0 10px;
    }
  }
}
</style>