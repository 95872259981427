<template>
  <div class="header">
    <div class="top_bar">
      <div class="top_left"></div>
      <div class="title">{{ title }}</div>
      <div class="top_right"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Vue20Header",
  props: ["title"],
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.top_bar {
  height: 44px;
  line-height: 44px;
  display: flex;
  .title {
    width: 0;
    flex: 1;
    text-align: center;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    color: #349ffd;
  }
  .top_left,
  .top_right {
    flex: 0 0 44px;
  }
}
</style>